import { useState } from 'react';

const useToggle = (startingValue = false) => {
  const [isOpen, setIsOpen] = useState(startingValue);

  const toggle = (boolean) => {
    setIsOpen((value) => {
      if (typeof boolean === 'boolean') {
        return boolean;
      }
      return !value;
    });
  };

  return [isOpen, toggle];
};

export default useToggle;
